<template>
    <div class="container">
        <div class="right_cont">
            <!-- <div class="title flex_center_start_box">
              <div class="title_txt flex_center">
                  <img src="../../assets/img/detail/title_ic4.png" />
                  HISTORY AND EVENTS
              </div>
          </div> -->
            <div class="his_box">
                <div class="head-title" v-if="parentTablenum1.length > 0">
                    Sanction Lists
                    <download-excel class="export-excel-wrapper" style="float: right" ref="exportExcel" :data="DetailsFormlist" :fields="json_fields"  name="Sanction List.xls">
                        <el-button :disabled="watchlisttable.length > 0 ? false : true" style="float: right; margin-bottom: 15px" class="btn_blue flex_center" @click="exportData">
                            <img src="../../assets/img/search/export_ic1.png" />
                            <span style="position: relative; top: -3px">Export</span>
                        </el-button>
                    </download-excel>
                </div>
                <div class="persion-table" v-if="parentTablenum1.length > 0">
                    <el-table class="el-table" ref="treeTable" :data="watchlisttable" border row-key="aaa_id" style="width: 100%" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column label="Name" width="800" prop="name" border align="left" show-overflow-tooltip :resizable="false"   >
                            <template slot-scope="scope">
                                <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                <div @click="setid(scope.row)" style="cursor: pointer; color: rgb(18, 144, 201)">
                                    {{ scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="From" prop="" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                <div v-if="scope.row?.from_date?.year > 0">
                                    <!-- {{
                                        new Date(scope.row?.from_date?.year, scope.row?.from_date?.month - 1, scope.row?.from_date?.day).toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                        })
                                    }} -->
                                          {{ scope.row?.from_date?.year }}-{{ scope.row?.from_date?.month }}-{{ scope.row?.from_date?.day }}
                                </div>
                                <div v-else></div>
                            </template>
                        </el-table-column>

                        <el-table-column label="To" :prop="showTranslate == true ? 'summary' : 'TO'" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                <div v-if="scope.row?.to_date?.year > 0">
                                    {{ scope.row?.to_date?.year }}-{{ scope.row?.to_date?.month }}-{{ scope.row?.to_date?.day }}
                                </div>
                                <div v-else></div>
                            </template>
                        </el-table-column>
                        <!-- 空数据状态的插槽 -->
                        <template slot="empty">
                            <div style="text-align: left">No Date</div>
                        </template>
                    </el-table>
                    <div class="page_i_box">
                        <Page :totalFont="true" :total="page1.total" @onPageChange="onPageChangeList1" @currtentPageChange1="currtentPageChange1"></Page>
                        <div class="search_rsult_txt">{{ page1.pageNo }}-{{ page1.pageSize }} of over {{ page1.total | FilterNum }} results</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { searchDetailsById } from '@/api/searchApi.js';
import Page from '@/components/page';

export default {
    components: { Page },
    data() {
        return {
            page1: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            activities: [],
            Numbersable: [],
            tableListnum: [],
            parentTablenum1: [],
            watchlisttable: [],
            showTranslate: false,
            json_fields: {
                'Name': 'name',

                'Form': 'from_date.year',
                'To':'to_date.year'

            },
            DetailsFormlist: [],
            DetailsForm:[],
            title: 'Sanctionlist',
        };
    },
    computed: {
        headerCellStyle() {
            return {
                backgroundColor: 'red', // 设置背景色
                // 其他样式属性
            };
        },
    },

    created() {
        this.getdetail();
    },
    methods: {
      handleSelectionChange(val){
console.log(`val`, val);
this.DetailsForm=val
      },
        exportData(data) {
            this.DetailsFormlist = this.DetailsForm;
        },
        setid(row) {
            console.log(`row`, row.sources[0]);
            if (row.sources[0].includes('http')) {
                console.log(`true`, true);
                let routeData = row.sources[0];
                window.open(routeData, '_blank');
            } else {
                let routeData = 'http://' + row.sources[0];
                window.open(routeData, '_blank');
            }
            // let routeData = row.url;
            // window.open(routeData, '_blank');
        },
        pageListid() {
            // console.log(this.parentTable);

            this.watchlisttable = this.parentTablenum1.filter((item, index) => index < this.page1.pageNo * this.page1.pageSize && index >= this.page1.pageSize * (this.page1.pageNo - 1));
            this.page1.total = this.parentTablenum1.length;
        },
        onPageChangeList1(pageNo) {
            // this.pageNo += 1
            this.page1.pageNo = pageNo;
            this.pageListid();
        },
        currtentPageChange1(pageSize) {
            // this.pageNo += 1
            this.page1.pageSize = pageSize;
            // this.init()
            this.pageListid();
        },

        getdetail() {
            searchDetailsById({ id: this.$route.query.id }).then(res => {
                console.log(`res`, res);

                this.parentTablenum1 = res.data.attributes?.list_reference?.sanctions_lists ? res.data.attributes?.list_reference?.sanctions_lists : [];
                // this.tableListnum = res.data.attributes.entity?.identification_details ? res.data.attributes.entity?.identification_details.concat(nums) : [];
                this.pageListid();
            });
        },
    },
};
</script>
<style scoped>
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.right_cont {
    margin-top: 60px;
}
.title {
    margin-bottom: 20px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.pageBox {
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}
.his_box {
    width: 100%;
    padding: 30px 30px 30px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px 14px 0px 0px;
}
.his_box /deep/ .el-timeline-item__wrapper {
    display: flex;
    padding-left: 14px;
}
.his_box /deep/ .el-timeline-item__node--normal {
    width: 8px;
    height: 8px;
    left: 1px;
}
.his_box /deep/ .el-timeline-item__node {
    background-color: #ff7600;
}
.his_box /deep/ .el-timeline-item__tail {
    border-left: 2px dashed #8497ab;
}
.his_box /deep/ .el-timeline-item__timestamp.is-top {
    font-size: 16px;
    color: #8497ab;
    padding-top: 0;
}
.his_box /deep/ .el-timeline-item__content {
    padding-bottom: 24px;
    font-size: 16px;
    color: #022955;
    border-bottom: 1px solid #e7eaed;
    margin-left: 20px;
    flex: 1;
    line-height: 26px;
    margin-top: -5px;
}
.head-title {
    /* color: #022955; */
    font-size: 16px;
    line-height: 35px;
    /* margin-top: 20px; */
    color: #ff7600;
    /* padding-left: 20px; */
    font-weight: 700;
    font-family: 'element-icons';
}
.his_box /deep/ .el-table th.el-table__cell {
    background: #f2f5f9;
}
.btn_blue {
    width: 140px;
    height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
}

.btn_blu {
    margin-right: 20px;
}

.btn_blue img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}
.his_box /deep/ .el-table .el-table-column--selection .cell {
    padding-left: 10px;
}
</style>
